import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/assets/style.less'
import axios from 'axios'
import qs from 'qs'
import request from '@/utils/request.js'



const app=createApp(App);
app.use(Vant)
app.use(router)
app.use(store)
app.mount('#app')
// router.beforeEach((to, from, next) => {
//     if(to.meta.title){
//         document.title = to.meta.title;
//     }
//     next()
// })

// 配置全局属性
app.config.globalProperties.$axios = axios;
 app.config.globalProperties.$http= 'https://web.qingniaoapp.vip';
 app.config.globalProperties.$mUrl= 'https://manage.qingniaoapp.vip';
//app.config.globalProperties.$http= 'http://server.silianapp.vip';
//app.config.globalProperties.$mUrl= 'http://manage.silianapp.vip';
app.config.globalProperties.$qs= qs;
app.config.globalProperties.$request= request;

// app.config.globalProperties.$request1= request;
	